import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { clone, diff, patch } from 'jsondiffpatch';

import activities from '@reducers/activities';
import auth from '@reducers/auth';
import barcode from '@reducers/barcode';
import categories from '@reducers/categories';
import config from '@reducers/config';
import customerDetail from '@reducers/customerDetail';
import customerInfo from '@reducers/customerInfo';
import customerMobileSubscription from '@reducers/customerMobileSubscription';
import favorites from '@reducers/favorites';
import filters from '@reducers/filters';
import goodies from '@reducers/goodies';
import jobs from '@reducers/jobs';
import menus from '@reducers/menus';
import notifications from '@reducers/notifications';
import products from '@reducers/products';
import profileImage from '@reducers/profileImage';
import shop from '@reducers/shop';
import vouchers from '@reducers/vouchers';
import mobilePlan from '@reducers/mobilePlan';
import homepage from '@reducers/homepage';
import shopEvent from '@reducers/shopEvent';
import basket from '@reducers/basket';
import defaultMetaInfo from '@reducers/defaultMetaInfo';
import giftCards from '@reducers/giftCards';
import wheelOfFortune from '@reducers/wheelOfFortune';
import addressApi from '@reducers/addressApi';

const combinedReducers = combineReducers({
  activities,
  auth,
  barcode,
  categories,
  config,
  customerDetail,
  customerInfo,
  customerMobileSubscription,
  favorites,
  filters,
  goodies,
  jobs,
  menus,
  notifications,
  products,
  profileImage,
  shop,
  vouchers,
  mobilePlan,
  homepage,
  shopEvent,
  basket,
  defaultMetaInfo,
  giftCards,
  wheelOfFortune,
  addressApi,
});

export default function reducers(state, action) {
  let newState;

  switch (action.type) {
    case HYDRATE: {
      if (!state.config.appInitialized) {
        newState = clone(state);

        const stateDiff = diff(state, action.payload);

        patch(newState, stateDiff);

        newState = {
          ...state,
          ...newState,
          config: {
            ...state.config,
            appInitialized: true,
          },
        };
      }
    }
    // falls through

    default:
      return combinedReducers(newState || state, action);
  }
}
