import React from 'react';

import { Text } from './Typography.styled';

const tagsMap = {
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'p',
  body5: 'p',
  body6: 'p',
  body7: 'p',
  body8: 'p',
  body9: 'p',
  body10: 'p',
  body11: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  paragraph1: 'p',
  paragraph2: 'p',
  subtitle1: 'p',
  subtitle2: 'p',
  div: 'div',
};

export type TagsMapKeysType = keyof typeof tagsMap;

interface TypographyProps {
  children: string | number | React.ReactNode;
  customAs?: TagsMapKeysType;
  variant?: TagsMapKeysType;
  maxLines?: number;
}

const Typography = React.forwardRef<HTMLParagraphElement, TypographyProps>(function Typography(
  { children, variant = 'body1', customAs, ...extraStyling },
  ref
) {
  // prop customAs - required to deep pass "as" component through many styled() extends:
  // https://github.com/styled-components/styled-components/issues/2129
  const as = customAs || tagsMap[variant as TagsMapKeysType];

  return (
    <Text as={as}
variant={variant as TagsMapKeysType}
ref={ref}
{...extraStyling}>
      {children}
    </Text>
  );
});

export default Typography;
