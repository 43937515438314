import {
  getAddressTokenRequest,
  getAddressTokenFailure,
  getAddressTokenSuccess,
} from '@actions/addressApi';

import request from '@utils/request';

import { addressApiToken } from '@common/endpoints';

export const fetchAddressApiToken = () => dispatch => {
  dispatch(getAddressTokenRequest());

  return request
    .get(addressApiToken())
    .then(({ data }) => {
      dispatch(getAddressTokenSuccess(data));
    })
    .catch(error => {
      dispatch(getAddressTokenFailure(error));
    });
};
