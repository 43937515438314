import * as actions from '@actions/goodies';
import { selectGetUID } from '@selectors/auth';

import request from '@utils/request';

import * as endpoints from '@common/endpoints';

export const fetchGoodies =
  (offset = 0, limit = 0, templateId) =>
  (dispatch, getState) => {
    const customerId = selectGetUID(getState());

    dispatch(actions.getGoodiesRequest());

    const params = {
      offset,
      limit,
    };

    if (templateId) {
      params.template = templateId;
    }

    return request
      .get(endpoints.goodies(customerId), { params })
      .then(({ data }) => {
        dispatch(actions.getGoodiesSuccess(data));
      })
      .catch(error => {
        dispatch(actions.getGoodiesFailure(error));
      });
  };

export const fetchGoodiesDetails = id => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.getGoodiesDetailsRequest(id));

  return request
    .get(endpoints.goodiesDetails(customerId, id))
    .then(({ data }) => {
      dispatch(actions.getGoodiesDetailsSuccess(id, data));

      return data;
    })
    .catch(error => {
      dispatch(actions.getGoodiesDetailsFailure(id, error));
    });
};

export const openGoodie = id => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.openGoodieRequest({ id }));

  return request
    .patch(endpoints.goodieOpen(customerId, id))
    .then(() => {
      dispatch(actions.openGoodieSuccess({ id }));
    })
    .catch(() => {
      dispatch(actions.openGoodieFailure({ id }));
    });
};
