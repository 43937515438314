import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '@actions/addressApi';

const initialState = {
  token: null,
  isFetching: false,
};

export default function addressApiReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.addressApi,
      };

    case actions.ADDRESS_TOKEN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actions.ADDRESS_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isFetching: false,
      };

    case actions.ADDRESS_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
