import { createGlobalStyle } from 'styled-components';

import { getThemeColor, getTypographyStyle } from '@utils/styled';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    box-sizing: border-box;
    font-size: 10px;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    font-family: inherit;
  }

  *::selection {
    background: rgba(128, 128, 182, 0.2);
    color: inherit;
  }

  img::selection {
    background: transparent;
    color: inherit;
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    background: ${({ theme: globalTheme }) => globalTheme.backgroundColor};
    color: ${getThemeColor('bluegrey.15')};
    font-size: 1.6em;
    font-family: ${({ theme: { font } }) => font};
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  sup {
    position: relative;
    top: 0.2em;
    margin-left: 0.15em;
    background: inherit;
    font-size: 0.7em;
    line-height: 0;
    vertical-align: super;
    -webkit-text-fill-color: inherit;
  }

  a {
    color: ${getThemeColor('hue.blue')};
    text-decoration: none;

    &:hover,
    &:focus,
    &:focus-visible {
      text-decoration: none;
    }

    &[href^="http"]:not([href*="://${({ domain }) => domain}"]) {
      &::after {
        content: url('/static/img/arrow-external.svg');
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 7px;
      }
    }
  }

  .option-card {
    &__school-days {
      p {
        ${getTypographyStyle('paragraph1')};
        margin-top: 0;
        font-weight: 500;

        :last-of-type {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    &__additional-info {
      p {
        ${getTypographyStyle('body2')};
        margin-bottom: 0;
        color: ${getThemeColor('bluegrey.60')};
        font-weight: 400;
      }
    }
  }
`;

export default GlobalStyle;
