import { isBrowser } from '@utils/environmentCheck';
import { numberToTwoDecimals } from '@utils/formatNumber';

export const webAppDataLayerName = 'webAppDataLayer';

const nonceGlobalVariableName = '_nncGtm';

export function gtmInit(WEB_APP_GTM_ID, dataLayerName) {
  if (!window[dataLayerName]) {
    window[dataLayerName] = [];
  }

  window[dataLayerName].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  const script = document.getElementsByTagName('script')[0];
  const scriptElement = document.createElement('script');

  scriptElement.async = true;
  scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${WEB_APP_GTM_ID}&l=${dataLayerName}`;

  const elementWithNonce = document.querySelector('[nonce]');

  if (elementWithNonce) {
    const nonce = elementWithNonce.nonce || elementWithNonce.getAttribute('nonce');

    scriptElement.setAttribute('nonce', nonce);

    if (!window[nonceGlobalVariableName]) {
      Object.defineProperty(window, nonceGlobalVariableName, {
        configurable: false,
        writable: false,
        enumerable: false,
        value: nonce,
      });
    }
  }

  script.parentNode.insertBefore(scriptElement, script);
}

export function webAppDataLayerPush(data) {
  if (isBrowser) {
    if (!window[webAppDataLayerName]) {
      window[webAppDataLayerName] = [];
    }

    window[webAppDataLayerName].push(data);
  }
}

const parseProductForWebApp = ({
  price,
  name,
  item_name,
  id,
  color_id,
  variant,
  size,
  productId,
  affiliation = 'Offline',
  currency = 'CHF',
  specialPrice,
  ordinaryPrice,
  quantity,
  ...product
}) => ({
  ...product,
  item_id: productId || id,
  item_name: item_name || name,
  item_size: size,
  item_variant: variant,
  item_variant_id: color_id,
  price,
  affiliation,
  currency,
  quantity,
  ...(specialPrice ? { discount: (ordinaryPrice - specialPrice) * quantity } : {}),
});

const parseOnlineShopProducts = products =>
  products.map(({ articleNr, title, specialPrice, price, size, colorTitle, quantity }) => {
    const [productId, colorId] = articleNr.split('-');

    return parseProductForWebApp({
      color_id: `${productId}-${colorId}`,
      id: productId,
      name: title,
      price: specialPrice || price,
      productId,
      size,
      variant: colorTitle,
      quantity,
      affiliation: 'Online',
      ordinaryPrice: price,
      specialPrice,
    });
  });

export function measureProductCardView(product) {
  webAppDataLayerPush({
    ecommerce: {
      items: [parseProductForWebApp(product)],
    },
    event: 'view_item_list',
  });
}

export function measureProductDetailsView(product) {
  webAppDataLayerPush({
    ecommerce: {
      items: [parseProductForWebApp(product)],
    },
    event: 'view_item',
  });
}

export function measureProductClick(product) {
  webAppDataLayerPush({
    ecommerce: {
      items: [parseProductForWebApp(product)],
    },
    event: 'select_item',
  });
}

export function setAnalyticsMasterSlug(masterSlug) {
  webAppDataLayerPush({
    masterSlug,
    event: 'screen_view',
  });
}

export function setAnalyticsUserId(userId) {
  webAppDataLayerPush({
    userId,
  });
}

export function clearPreviousEcommerceLayer() {
  webAppDataLayerPush({
    ecommerce: null,
  });
}

export function measureAddToFavorites(value, product) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      currency: 'CHF',
      value,
      items: [parseProductForWebApp(product)],
    },
    event: 'add_to_wishlist',
  });
}

export function measureAddToBasket(shopEventId, value, product) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      items: [parseProductForWebApp(product)],
    },
    event: 'add_to_cart',
  });
}

export function measureRemoveFromBasket(shopEventId, value, product) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      items: [parseProductForWebApp(product)],
    },
    event: 'remove_from_cart',
  });
}

export function measureBasketView(shopEventId, value, products) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      items: parseOnlineShopProducts(products),
    },
    event: 'view_cart',
  });
}

export function measureBeginCheckout(shopEventId, value, products) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      items: parseOnlineShopProducts(products),
    },
    event: 'begin_checkout',
  });
}

export function measureCheckoutCustomerInformation(shopEventId, value, checkout_type, products) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      checkout_type,
      items: parseOnlineShopProducts(products),
    },
    event: 'add_customer_information',
  });
}

export function measureShippingInfo(shopEventId, value, products) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      items: parseOnlineShopProducts(products),
    },
    event: 'add_shipping_info',
  });
}

export function measurePurchase(shopEventId, value, { transactionId, shipping }, products) {
  clearPreviousEcommerceLayer();
  webAppDataLayerPush({
    ecommerce: {
      shopEventId,
      currency: 'CHF',
      value,
      transaction_id: transactionId,
      shipping,
      affiliation: 'Online',
      items: parseOnlineShopProducts(products),
    },
    event: 'purchase',
  });
  clearPreviousEcommerceLayer();
}

const getDiscountedPrice = (price, discount) =>
  numberToTwoDecimals(price - price * (discount / 100));

const parseGiftCardsItems = (giftCards, discount, currency) => {
  const uniqueGiftCardValues = new Set(Object.values(giftCards).map(giftCard => giftCard.value));

  return Array.from(uniqueGiftCardValues).map(value => {
    const quantity = giftCards.filter(giftCard => giftCard.value === value).length;
    const discountedPrice = getDiscountedPrice(value, discount);

    return {
      item_id: `GIFT_CARD_${currency}_${value}`,
      item_name: `Gift card ${currency} ${value}`,
      discount: numberToTwoDecimals(value - discountedPrice),
      price: discountedPrice,
      quantity,
    };
  });
};

export function measureGiftCardsPurchase(giftCards, discount = 0, transactionId, value) {
  const currency = 'CHF';
  const output = {
    ecommerce: {
      transaction_id: transactionId,
      affiliation: 'Online',
      currency,
      value: getDiscountedPrice(value, discount),
      shipping: 0,
      tax: 0,
      coupon: null,
      items: parseGiftCardsItems(Object.values(giftCards), discount, currency),
    },
    event: 'purchase',
  };

  clearPreviousEcommerceLayer();
  webAppDataLayerPush(output);
  clearPreviousEcommerceLayer();
}
