import cookie from 'cookie';
import getConfig from 'next/config';

import { localLogin, localLogout, localSession } from '@common/endpoints';

const { FRONTEND_BASE_URL } = getConfig().publicRuntimeConfig;

export const storeAccessToken = async data => {
  await fetch(localLogin(), {
    method: 'POST',
    body: JSON.stringify({ accessToken: data.token }),
  });
};

export const clearAccessToken = async () => {
  await fetch(`${FRONTEND_BASE_URL}${localLogout()}`, {
    method: 'GET',
  });
};

export const getSession = async () =>
  fetch(localSession(), {
    method: 'GET',
  }).then(response => response.json());

export const getAccessToken = req => cookie.parse(req.headers.cookie || '').accessToken;
