/* eslint-disable global-require */
import { combineReducers } from 'redux';

import discount from './discount';
import cardValues from './cardValues';
import order from './order';
import payment from './payment';

const giftCardsReducer = combineReducers({
  discount,
  cardValues,
  order,
  payment,
});

export default giftCardsReducer;
