export const ADDRESS_TOKEN_REQUEST = 'GET_ADDRESS_TOKEN_REQUEST';
export const ADDRESS_TOKEN_SUCCESS = 'GET_ADDRESS_TOKEN_SUCCESS';
export const ADDRESS_TOKEN_FAILURE = 'GET_ADDRESS_TOKEN_FAILURE';

export const getAddressTokenRequest = () => ({
  type: ADDRESS_TOKEN_REQUEST,
});

export const getAddressTokenSuccess = payload => ({
  payload,
  type: ADDRESS_TOKEN_SUCCESS,
});

export const getAddressTokenFailure = () => ({
  type: ADDRESS_TOKEN_FAILURE,
});
