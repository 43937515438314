import axios from 'axios';
import getConfig from 'next/config';
import { toCamelCase, toSnakeCase } from 'case-converter';
import Router from 'next/router';
import MockAdapter from 'axios-mock-adapter';

import { clearAccessToken } from '@utils/jwt';
import { isBrowser } from '@utils/environmentCheck';
import { getLinkDataFromUrl } from '@utils/urls';

const { BACKEND_URL } = getConfig().publicRuntimeConfig;

class Request {
  constructor() {
    this.initConfig();
    this.token = null;
    this.isRedirecting = false;
  }

  setAuthorizationToken(token) {
    this.token = token;
  }

  removeAuthorizationToken() {
    this.token = null;
    delete this.instance.defaults.headers.common.Authorization;
  }

  setAcceptLanguage(language) {
    this.instance.defaults.headers.common['Accept-Language'] = language;
  }

  initConfig() {
    this.instance = axios.create({
      baseURL: BACKEND_URL,
    });

    this.mock = new MockAdapter(this.instance, { onNoMatch: 'passthrough' });

    this.instance.defaults.headers.common['Call-Source'] = isBrowser ?
      'frontend-browser' :
      'frontend-node';

    this.instance.interceptors.request.use(async config => {
      const { token } = this;

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
        data: config.data ? toSnakeCase(config.data) : config.data,
        params: config.params ? toSnakeCase(config.params) : config.params,
      };
    });

    this.instance.interceptors.response.use(
      response => ({
        ...response,
        data: toCamelCase(response.data),
      }),
      async error => {
        if (error.response?.status === 401) {
          await clearAccessToken();

          this.removeAuthorizationToken();
        }

        if (error.response?.data?.redirect && isBrowser) {
          const redirectPath = getLinkDataFromUrl(error.response.data?.redirect);

          if (redirectPath && !this.isRedirecting) {
            this.isRedirecting = true;

            const { asPath, locale } = Router;
            const query = {
              target: `/${locale}${asPath}`,
            };

            if (error.response.data?.friendly_error) {
              query.friendlyError = error.response.data?.friendly_error;
            }

            if (error.response.data?.friendly_success) {
              query.friendlySuccess = error.response.data?.friendly_success;
            }

            await Router.push(
              {
                pathname: redirectPath.urlObject.pathname,
                query,
              },
              redirectPath.as
            );

            this.isRedirecting = false;
          }
        }

        return Promise.reject(this.parseError(error));
      }
    );
  }

  parseError(error) {
    const { response = {} } = error;

    return {
      ...error,
      response: {
        ...response,
        data: {
          ...toCamelCase(response.data),
          _error: response.data ? response.data.non_field_errors : [],
        },
      },
    };
  }

  get(...args) {
    return this.instance.get(...args);
  }

  post(...args) {
    return this.instance.post(...args);
  }

  options(...args) {
    return this.instance.options(...args);
  }

  patch(...args) {
    return this.instance.patch(...args);
  }

  put(...args) {
    return this.instance.put(...args);
  }

  delete(...args) {
    return this.instance.delete(...args);
  }
}

export default new Request();
